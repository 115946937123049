<template>
	<div class="w-full h-full flex flex-col overflow-auto ">
		
		<div class="w-full h-full flex flex-col">
			<Header :classes="'items-end'">
				<div>
					<h1>Oversikt</h1>
				</div>
				
				<Tabs 
					class="flex-none justify-center md:justify-start"
					:tabs="tabs" 
					:active="activeSection" 
					@click="activeSection = $event.alias"
				/>
			</Header>
			
			<OverviewNavigation 
				v-if="activeSection == 'historic'"
				@loading="loading = $event"
				@fetchedStatsHistoric="statsHistoric = $event"
				class="flex-none px-6 py-4"
			/>
			
			<div class="w-full h-full flex flex-col overflow-hidden">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<Overview 
						v-if="activeSection == 'current'"
						class="max-w-screen-lg mx-auto"
						:stats="stats" 
						:loading="loading"
						:userStats="false"
					/>
					<Overview
						v-else-if="activeSection == 'historic'"
						class="max-w-screen-lg mx-auto"
						:stats="statsHistoric" 
						:loading="loading"
						:userStats="false"
						:useDepartmentTargets="false"
					/>
				</div>
			</div>
		</div>
		<TransactionNotice 
			:showGiveKudo="false"
		/>
	</div>
</template>



<script>
	import PortalMixins from '@/mixins/PortalMixins';

	export default {
		mixins: [
			PortalMixins,
		],
		
		components: {
			'Overview': () => import('@/components/stats/Overview.vue'),
			'OverviewNavigation': () => import('@/components/stats/OverviewNavigation.vue'),
			'TransactionNotice': () => import('@/components/TransactionNotice.vue'),
		},
		
		data(){
			return {
				loading: null,
				stats: null,
				pollingIntervalFunction: null,
				
				overviewMode: 'current',
				statsHistoric: null,
				tabs: [
					{
						name: 'Gjeldende periode',
						alias: 'current',
					},
					{
						name: 'Historisk',
						alias: 'historic',
					},
				],
				activeSection: 'current',
			}
		},
		
		
		methods: {
			async listStats(){
				try {
					const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/stats', {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					this.stats = response.data;
				} 
				catch (e) {
					this.error = { 
						type: 'error', 
						text: e.message || 'Kunne ikke hente statistikk'
					};
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			// const accountRaw = await this.getAccount( this.account.accessToken );
			// 
			// const account = {
			//     ...accountRaw,
			//     portal: this.account.portal,
			//     accessToken: this.account.accessToken,
			// };
			// 
			// 
			// await this.$store.dispatch('setAccount', account);
			
			this.loading = 'fetching';
			await this.listStats();
			
			this.pollingIntervalFunction = setInterval( () => {
				this.listStats();
			}, 1000 * 60);
		},
		
		beforeDestroy() {
			if (this.pollingIntervalFunction) {
				clearInterval(this.pollingIntervalFunction);
			}
		},
	}
</script>