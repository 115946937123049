export default {
    data() {
        return {
            loading: null,
        }
    },
    
    methods: {
        async getAccount( accessToken ){
            try {
                const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/account', {
                    headers: {
                        authorization: accessToken, //this.account.accessToken,
                    },
                });
                
                // console.log('account', response.data);
                return response.data;
            } 
            catch (e) {
                this.error = { 
                    type: 'error', 
                    text: e.message || 'Kunne ikke hente Upsell kontodetaljer'
                };
        
                console.error(e);
            } 
        },
        
        async signIn(){
            try {
                this.loading = 'checking';
                
                if ( !this.accessCode ) {
                    throw Error('Du må taste inn en tilgangskode');
                }
                
                this.signOut();
                
                const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/portal', {
                    accessCode: this.accessCode,
                });
                
                localStorage['accessToken'] = response.data.accessToken;
                localStorage['portal'] = JSON.stringify( response.data.portal );
                
                this.accessToken = response.data.accessToken;
                this.portal = response.data.portal;
                
                const accountRaw = await this.getAccount( response.data.accessToken );
                
                const account = {
                    ...accountRaw,
                    portal: response.data.portal,
                    accessToken: response.data.accessToken,
                };
                
                await this.$store.dispatch('setAccount', account);
            } 
            catch (e) {
                this.error = { 
                    type: 'error', 
                    text: e.message || 'Kunne ikke logge inn'
                };
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        async signOut(){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('portal');
            localStorage.removeItem('showAddToHomeScreen');
            this.accessToken = null;
            this.portal = null;
            await this.$store.dispatch('setAccount', null);
            
            // this.$router.push({ name: 'portal-signin' });
        },
    },
}
